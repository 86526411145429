<template>
  <div style="padding: 10px">
    <div class="relative">
      <div class="w-full max-w-[650px] rounded-lg overflow-hidden">
        <img src="bg_image.png" class="w-full h-full object-cover" />
      </div>
      <h2
        class="absolute text-xl text-white font-bold bottom-4 left-1/2 -translate-x-1/2 text-center"
      ></h2>
    </div>
    <h5 class="text-center mt-2">
      To register for FOSC, proceed to fill the form below
    </h5>

    <form class="mt-6 mb-6" @submit.prevent="payViaService">
      <div class="grid gap-4 mb-6 md:grid-cols-2">
        <div>
          <input
            type="text"
            v-model="firstname"
            id="first_name"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="First Name"
            required
          />
        </div>
        <div>
          <input
            type="text"
            v-model="lastname"
            id="last_name"
            class="bg-gray-50 border border-sky-500 text-sky-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Last Name"
            required
          />
        </div>
        <div>
          <input
            type="tel"
            id="phone"
            v-model="phone"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Phone Number"
            required
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            v-model="email"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Email"
            required
          />
        </div>
        <div>
          <select
            id="chapter"
            v-model="chapter"
            @change="calculateAmount"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
          >
            <option selected value="city_chapter">City Chapter</option>
            <option value="collegiate_chapter">Collegiate Chapter</option>
            <option value="non_jaycee">Non Jaycee</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            v-model="chapter_name"
            id="chapter_number"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Chapter Name"
            required
          />
        </div>
        <div>
          <select
            id="payment_type"
            v-model="payment_type"
            @change="calculateAmount"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
          >
            <option selected value="individual_payment">
              Individual Payment
            </option>
            <option value="bulk_payment">Bulk Payment</option>
          </select>
        </div>
        <div>
          <input
            type="number"
            id="payment_number"
            v-model="payment_number"
            @input="calculateAmount"
            :min="payment_type === 'bulk_payment' ? 10 : 0"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="1"
            required
          />
        </div>

        <div>
          <input
            type="text"
            id="games"
            v-model="games"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Preferred Game(s)"
            required
          />
        </div>
        <div>
          <input
            type="text"
            id="food"
            v-model="foods"
            class="bg-gray-50 border border-sky-500 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
            placeholder="Preferred Food"
            required
          />
        </div>
      </div>
      <div class="text-center mb-6">
        <button
          type="submit"
          class="text-white font-bold bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:outline-none w-64 focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-sky-500 dark:focus:ring-sky-300focus:ring-sky-300 dark:focus:ring-sky-800"
        >
          Proceed to pay {{ total !== 0 ? "#" + total : "" }}
        </button>
      </div>
    </form>

    <h4 class="text-center">
      Powered by
      <a
        href="https://sanwo.io"
        target="_blank"
        class="text-blue-600 dark:text-yellow-500 hover:underline underline"
        >Sanwo</a
      >
    </h4>
    <img src="logo_long.png" class="mx-auto" alt="" />
  </div>
</template>

<script>
import { firestore } from "@/firebase";

export default {
  name: "HomeView",
  data() {
    return {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      chapter: "city_chapter",
      chapter_name: "",
      payment_type: "individual_payment",
      payment_number: null,
      games: "",
      foods: "",

      total: 0,

      totalPayable: 0,
    };
  },
  methods: {
    payViaService() {
      this.saveToFirestore("PENDING");
      this.payWithFlutterwave({
        tx_ref: this.generateReference(),
        amount: this.totalPayable,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd,banktransfer",
        redirect_url: "https://folusooluleye.jciibadan.com/success",
        meta: {
          transaction_for: "fosc_payment",
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          email: this.email,
          chapter: this.chapter,
          chapter_name: this.chapter_name,
          payment_type: this.payment_type,
          payment_number: this.payment_number,
          games: this.games,
          foods: this.foods,
          total: this.total,
          commission: this.totalCommission,
          totalPayable: this.totalPayable,
          timestamp: new Date().toLocaleString(),
        },
        customer: {
          email: this.email,
          phone_number: this.phone,
          name: this.firstname + " " + this.lastname,
        },
        customizations: {
          title: "FOSC Payment",
          description: "Payment for Foluso Oluleye Sport Competition",
          logo: "logo.png",
        },
        callback: this.makePaymentCallback,
        onclose: this.closedPaymentModal,
      });
    },

    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },

    makePaymentCallback(response) {
      this.saveToFirestore("PAID", response.tx_ref, response.flw_ref);
    },
    closedPaymentModal() {
      console.log("payment is closed");
      this.saveToFirestore("CANCELLED");
    },

    saveToFirestore(status, txRef = "", flwRef = "") {
      firestore.collection("records").add({
        transaction_for: "fosc_payment",
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        email: this.email,
        chapter: this.chapter,
        chapter_name: this.chapter_name,
        payment_type: this.payment_type,
        payment_number: this.payment_number,
        games: this.games,
        foods: this.foods,
        total: this.total,
        commission: this.totalCommission,
        totalPayable: this.totalPayable,
        tx_ref: txRef,
        flw_ref: flwRef,
        status: status,
        timestamp: new Date().toLocaleString(),
      });
    },

    calculateAmount() {
      this.total = 0;

      this.totalCommission = 0;
      if (
        this.chapter === "city_chapter" &&
        this.payment_type === "individual_payment"
      ) {
        this.total = 5000 * this.payment_number;
      } else if (
        this.chapter === "collegiate_chapter" &&
        this.payment_type === "individual_payment"
      ) {
        this.total = 3500 * this.payment_number;
      } else if (
        this.chapter === "collegiate_chapter" &&
        this.payment_type === "bulk_payment"
      ) {
        if (this.payment_number >= 10) this.total = 3500 * this.payment_number;
      } else if (
        this.chapter === "city_chapter" &&
        this.payment_type === "bulk_payment"
      ) {
        this.total = 5000 * this.payment_number;
      } else if (
        this.chapter === "non_jaycee" &&
        this.payment_type === "individual_payment"
      ) {
        this.total = 5000 * this.payment_number;
      } else if (
        this.chapter === "non_jaycee" &&
        this.payment_type === "bulk_payment"
      ) {
        this.total = 5000 * this.payment_number;
      }

      if (this.payment_type === "individual_payment") {
        this.totalCommission =
          this.total + 0.014 * this.total + 100 * this.payment_number;
      } else if (this.payment_type === "bulk_payment") {
        this.totalCommission = this.total + 0.014 * this.total + 1000;
      }

      // this.total + (100 * this.payment_number)

      this.totalPayable = this.totalCommission;
    },
  },
};
</script>
