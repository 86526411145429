import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
    apiKey: "AIzaSyDIQQn62lqqbxExq98NYSvvt_5WDlzUqqY",
    authDomain: "sanwo-fosc.firebaseapp.com",
    projectId: "sanwo-fosc",
    storageBucket: "sanwo-fosc.appspot.com",
    messagingSenderId: "953160868791",
    appId: "1:953160868791:web:dc02dc1259b92cdbb4fe32",
    measurementId: "G-VECFTJD1F2"
};

firebase.initializeApp(config);
const firestore = firebase.firestore();

export {firebase, firestore };